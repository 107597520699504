/*
 * Property of InfraGIS GmbH
 * Written by Tom Palan <t.palan@infragis.at>
 *
 * Main entry point for desktop application
 */
import {IGISApi} from "@igis-common/api/IGISApi";
import {IGIS} from "./igis-base";

// api
const api = new IGISApi('/api/', '/wms/');
// main igis application
const igis = new IGIS(api);

// try login right away while we are initializing the gui, maybe we can load stuff in the meantime
igis.checkLoginStatus();
igis.init();

