
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class ExportGen extends Model {
  protected _name: string;
  protected _id: number;
  protected _featureCount: number;
  protected _srid: string;

  constructor(json: {name: string, id: number, featureCount: number, srid: string}, public _api: IGISApi) {
    super();
    this._name = json.name;
    this._id = json.id;
    this._featureCount = json.featureCount;
    this._srid = json.srid;
    this.onNew();
  }
}
