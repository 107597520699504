import {LeafletObject, MapComponent} from "@igis-common/component/MapComponent";
import * as L from "leaflet";
import {
  CircleMarker,
  DivIcon,
  DomEvent,
  ImageOverlay,
  LatLng,
  LatLngBounds,
  LeafletMouseEvent,
  Marker,
  Polyline
} from "leaflet";
import * as Wkt from 'wicket';
import {RootLayer} from "@igis-common/RootLayer";
import {ImageFeatureLayer} from "@igis-common/leaflet/ImageFeatureLayer";
import {FI} from "@igis-common/model/FI";
import "@iconfu/svg-inject";
import "leaflet-easybutton/src/easy-button.css";
import "leaflet-easybutton";
import {LevelFeature} from "@igis-common/model/Feature"; // for loading svg inline into <img tags, otherwise styling does not work
import pathMoveOverlaySVG from '../image/move_overlay.svg';
import {ImageMapFeaturePoint} from "@igis-common/leaflet/ImageMapFeaturePoint";
import {FeatureComponent} from "@igis-common/component/FeatureComponent";

interface LegendItem {
  name: string;
  svgUrl: string;
}
interface LegendItems {
  [key: string]: LegendItem
}
interface LegendLayer {
  name: string;
  items: LegendItems;
}
interface Legend {
  [key: string]: LegendLayer;
}

export class ImageMapComponent extends MapComponent {

  protected closeButton: L.Control.EasyButton | null = null;

  protected imageOverlay: ImageOverlay | null = null;

  protected curLevelFeature: LevelFeature | null = null;

  protected curLayers: ImageFeatureLayer[] = [];


  public init() {
    super.init();

    this.app.levelFeature.selectLevelFeature$.subscribe(levelFeature => this.onNewLevelFeature(levelFeature));

    this.map$.subscribe(map => {
      // create button for closing the image map
      this.closeButton = L.easyButton({
        position: 'topright', states: [{
          stateName: 'default',
          title: 'Ansicht verlassen',
          icon: '<i style="color:darkred" class="fa-duotone fa-window-close"></i>',
          onClick: () => {
            this.onCloseClick();
          }
        }]
      }).addTo(map);

      // add a click handler for de-selecting the currently selected feature
      map.on('click', (e: LeafletMouseEvent) => {
        // publish click events
        this.onMapClick(e);
      });

    })
  }

  public async getVisibleLegendItems(): Promise<Legend> {
    const legendLayers: Legend = {};
    for(const layer of this.curLayers) {
      const visFeatures = layer.getVisibleFeatures();
      // group by raw value
      const diffFeatures = {};
      for(const feature of visFeatures) {
        if (feature instanceof ImageMapFeaturePoint) {
          diffFeatures[feature.visRawValue] = feature;
        }
      }
      const legendItems: LegendItems = {};
      for(const rawValue in  diffFeatures) {
        const feature = diffFeatures[rawValue];
        // fetch extinfo + layer info for this one feature
        await FeatureComponent.fetchExtFeatureInfo(this.app.api, feature.fi);
        feature.fi.renderValues();
        legendItems[feature.visRawValue] = {
          name: feature.visName,
          svgUrl: feature.svgURL
        };
      }
      if (Object.keys(legendItems).length > 0) {
        legendLayers[layer.layer.id] = {
          name: layer.layer.name,
          items: legendItems,
        };
      }
    }
    return legendLayers;
  }

  protected onMapClick(mouseEvent: LeafletMouseEvent) {
    // when the map was clicked it means no feature was clicked: de-select the currently selected feature
    this.app.feature.clearSelectedFeature();
  }

  protected onCloseClick(): void {
    this.app.levelFeature.clearSelectedLevelFeature(); // we exit this screen and switch back to WMS map
  }

  /**
   * This overload of the method is used only for moving geometry markers.
   * @param feature
   * @param options
   * @protected
   */
  public createLeafletObjectForFeature(feature: FI, options: any): LeafletObject {

    // convert to leaflet data
    const wktGeom = feature.wkt;
    const wkt = new Wkt.Wkt();
    wkt.read(wktGeom);
    wkt.write();

    let objectCoords = wkt.components;

    // create correct leaflet object
    let object : any;
    switch (wkt.type) {
      case 'point':
        const url = feature.getFeatureImageURL();
        let svg = '<img class="img-top" alt="feature marker" src="' + url + '" onload="SVGInject(this)"/>';
        svg += '<img class="img-top" alt="feature overlay" src="' + pathMoveOverlaySVG + '" onload="SVGInject(this)"/>';
        const icon = new DivIcon({
          html: svg,
          iconSize: [50, 50]
        });
        const coords = objectCoords[0];
        object = new Marker(new LatLng(coords.y, coords.x), {...{icon: icon}, ...options});
        break;
      case 'linestring':
      case 'multilinestring':
      case 'polygon':
      case 'multipolygon':
        object = new Polyline(objectCoords, {...{color: 'blue', weight: 5}, ...options});
        break;
      default:
        console.log('unknown feature geometry');
        object = new CircleMarker(objectCoords[0], {...{color: 'blue', radius: 20, weight: 5}, ...options});
        break;
    }

    object.on('click', (event) => {
      DomEvent.stop(event); // prevent marker click from bubbling up to map click
    })

    return <LeafletObject>object;
  }

  protected onNewLevelFeature(levelFeature: LevelFeature | null) {
    // remove our basemap
    if (this.imageOverlay) {
      this.imageOverlay.remove();
    }
    // remove old layers
    for (let layer of this.curLayers) {
      layer.remove();
    }
    this.curLayers = [];

    if (levelFeature && this.map) {
      console.log('init level feature');
      this.curLevelFeature = levelFeature;
      const feature = levelFeature.feature;

      // init map
      const bounds = new LatLngBounds([[0, 0], [1000, 1000]]);

      // init the image layer of this level
      const storageEntry = levelFeature.level.image;
      this.imageOverlay = new ImageOverlay('/storage/' + storageEntry.uuid, bounds).addTo(this.map);
      this.map.fitBounds(bounds);
      this.map.setMaxBounds(bounds);

      // add feature layers & set our root layer
      const root = new RootLayer('Kartenebenen', this.app.api);
      const layers = feature.layer.imageLayers
      for (let layer of layers) {
        root.addChild(layer);
        // add an image feature layer for every image layer
        const imageLayer = new ImageFeatureLayer(layer, levelFeature, this.app);
        imageLayer.addTo(this.map);
        this.curLayers.push(imageLayer);
      }
      this.rootLayerSubject.next(root);
    }
  }

  public queryFeature(layerId: number, guid: string): Promise<FI | null> {
    const levelFeature = this.curLevelFeature;
    const params =  {
      layerId,
      fguid: guid,
      levelFeatureGUID: levelFeature ? levelFeature.feature.guid : undefined,
      levelId: levelFeature ? levelFeature.level.id : undefined,
      levelLayerId: levelFeature ? levelFeature.feature.layer.id : undefined
    };
    return this.app.api.getFeatureInfo(params).then(res => {
      if (res) {
        const fi = res.data;
        if (fi) {
          // inject our level information, otherwise we cannot fetch ext-fi
          fi.levelFeature = this.curLevelFeature;
        }
        return fi;
      } else {
        return null;
      }
    });
  }

  /**
   * Overloaded to limit zooming to level 2 while in image map
   * @param lat
   * @param lon
   */
  public flyTo(lat: number, lon: number) {
    this.map?.flyTo(new L.LatLng(lat, lon), 2, {duration: 1});
  }
}
