import {StorageEntryGen} from "./generated/StorageEntryGen";
import {DataEntry} from "@igis-common/model/DataEntry";

export class StorageEntry extends StorageEntryGen {

  // used for holding association to data entries
  protected _dataEntry: DataEntry | null = null;

  get dataEntry(): DataEntry | null {
    return this._dataEntry
  }

  set dataEntry(dataEntry) {
    this._dataEntry = dataEntry;
  }

  get uuid(): string {
    return this._uuid;
  }

  get filename(): string {
    return this._filename;
  }

  get isPicture(): boolean {
    return this._isPic;
  }

  get hasThumbnail(): boolean {
    return this._hasThumb;
  }

  get isPanorama(): boolean {
    return this._isPanorama;
  }

  get comment(): string {
    return this._comment;
  }

  get isLink(): boolean {
    if (!this._href) {
      return false;
    }
    return this._href.length > 0;
  }

  get url(): string {
    return `/storage/${this.uuid}`;
  }

  get filenameOrComment(): string {
    if (this.filename && this.filename.length > 0) {
      return this.filename;
    }
    return this.comment;
  }
}
