import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {first} from "rxjs/operators";
import {ExtWindow} from "../../ext-types/window/ExtWindow";
import {IExt} from "../../ext-types/Ext";
import {ExtFormFieldText} from "../../ext-types/form/field/ExtFormFieldText";
import {LOGIN_RESULT} from "@igis-common/api/IGISApi";

const w = 350;
const h = 200;

export class LoginComponent extends Component {

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {
      this.app.needLogin$.subscribe(() => {
        // we've been notified that we don't have an old, valid session -> show login dialog
        console.log('showing login dialog');
        this.showDialog(Ext);
      })
    })
  }

  protected showDialog(Ext: IExt): void {
    const app = this.app;
    const LoginDialogDefinition = {
      xtype: 'window',
      title: 'InfraGIS login',
      modal: true,
      frame: true,
      layout: 'fit',
      resizable: false,
      closable: false,
      referenceHolder: true,
      width: w,
      items: [{
        xtype: 'form',
        bodyPadding: 10,
        defaultType: 'textfield',
        items: [{
          id: 'LoginUserField',
          allowBlank: false,
          fieldLabel: 'eMail',
          name: 'user'
        }, {
          id: 'LoginPasswordField',
          allowBlank: false,
          fieldLabel: 'Passwort',
          name: 'password',
          inputType: 'password'
        }, {
          id: 'LoginOTPField',
          allowBlank: true,
          fieldLabel: 'Auth-Code',
          name: 'otp',
          hidden: true
        }],
        defaultButton: 'buttonLogin',
        buttons: [{
          reference: 'buttonLogin',
          text: 'Login',
          formBind: true,
          id: 'buttonLogin',
          handler: function () {
            const form = this.up('form').getForm();
            const userField = Ext.getCmp<ExtFormFieldText>('LoginUserField');
            const passwordField = Ext.getCmp<ExtFormFieldText>('LoginPasswordField');
            const otpField = Ext.getCmp<ExtFormFieldText>('LoginOTPField');
            if (!form.isValid()) {
              console.log('login form not valid');
            } else {
              // disable form until we return
              this.disable();
              // make the request
              app.login(<string>userField.getValue(), <string>passwordField.getValue(), <string>otpField.getValue()).then(result => {
                switch (result) {
                  case LOGIN_RESULT.SUCCESS:
                    // success
                    // close the dialog
                    this.up('window').destroy();
                    break;
                  case LOGIN_RESULT.DENIED:
                    Ext.Msg.alert('Login fehlgeschlagen', 'Ungültige Zugangsdaten.');
                    this.enable();
                    break;
                  case LOGIN_RESULT.EXPIRED:
                    Ext.Msg.alert('Login fehlgeschlagen', 'Ihr Benutzerkonto ist abgelaufen oder wurde deaktiviert. Bitte kontaktieren Sie den InfraGIS Support.');
                    this.enable();
                    break;
                  case LOGIN_RESULT.OTP_REQUIRED:
                    this.enable();
                    otpField.setVisible(true);
                    //otpField.setRequired(true);
                    otpField.focus();
                    break;
                  default:
                    Ext.Msg.alert('Login fehlgeschlagen', 'Unbekannter Fehler. Bitte versuchen Sie es später erneut.');
                    this.enable();
                    break;
                }
              }).catch(() => {
                // there was an error
                this.enable();
              })
            }
          }
        }],
        defaults: {
          anchor: '100%',
          labelWidth: 120
        }
      }]
    }

    const dlg = <ExtWindow>Ext.create(LoginDialogDefinition);
    dlg.show();
  }
}
