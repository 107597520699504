
import {Export} from '../Export';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class ExportsGen extends Model {
  protected _exports: Export[];

  constructor(json: {exports: {name: string, id: number, featureCount: number, srid: string}[]}, public _api: IGISApi) {
    super();
    this._exports =  json.exports.map(item => new Export(item, _api));
    this.onNew();
  }
}
