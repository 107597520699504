import {Newable} from "./Newable";
import {ApiResult, ERROR_CODE, IGISApiGen} from "./IGISApiGen";
import {RPCParams} from "../base";
import {lastValueFrom, Observable, ReplaySubject, Subject, take} from "rxjs";
import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {ProjectListEntry} from "@igis-common/model/ProjectListEntry";
import {FIResultFromXML} from "@igis-common/model/FIResultFromXML";
import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {Layer} from "@igis-common/model/Layer";
import {FI} from "@igis-common/model/FI";
import {CRS, LatLngBounds} from "leaflet";
import {map, switchMap} from "rxjs/operators";
import {StorageEntry} from "@igis-common/model/StorageEntry";
import {UploadResult} from "@igis-common/model/UploadResult";
import {MapPos} from "@igis-common/component/MapComponent";
import {WebsocketClient, WsMessage} from "@igis-common/api/WebsocketClient";
import {Feature} from "@igis-common/model/Feature";
import {Uppy} from "@uppy/core";
import XHR from '@uppy/xhr-upload';
import {jwtDecode} from "jwt-decode";
import {v4 as uuidv4} from 'uuid';


interface ApiResponse {
  success: boolean;
  error_msg: string;
  error_code: ERROR_CODE,
  data?: any;
}

export enum LOGIN_RESULT {
  SUCCESS,
  OTP_REQUIRED,
  EXPIRED,
  FAILURE,
  DENIED
}

export interface ApiErrorResponse {
  errorMsg: string;
  errorCode: ERROR_CODE,
  call: string;
}

export interface WMSErrorResponse {
  errorMsg: string;
  code: string;
}

export interface Point {
  x: number;
  y: number;
}

interface UploadMap {
  [fileId: string]: FI
}

export class IGISApi extends IGISApiGen {

  public getProjectId(): number {
    return this.projectId;
  }

  protected apiClient: AxiosInstance;
  protected wmsClient: AxiosInstance;

  /**
   * Publishes ProjectInfo structure when available
   */
  protected projectInfoSubject = new ReplaySubject<ProjectInfo>(1);
  public projectInfo$: Observable<ProjectInfo> = this.projectInfoSubject;

  /**
   * Publishes the project list
   */
  protected projectListSubject = new Subject<ProjectListEntry[]>();
  public projectList$: Observable<ProjectListEntry[]> = this.projectListSubject;

  /**
   * Publishes our current request token
   */
  protected tokenSubject = new ReplaySubject<string | null>(1);
  public token$: Observable<string | null> = this.tokenSubject;

  /**
   * Emits if currently async http requests are running
   */
  protected requestActiveSubject = new Subject<boolean>();
  public requestActive$: Observable<boolean> = this.requestActiveSubject;


  /**
   *  Does transmit API errors (except SESSION_CLOSED / NO_SESSION)
   */
  protected apiErrorSubject = new ReplaySubject<ApiErrorResponse>();
  public apiError$: Observable<ApiErrorResponse> = this.apiErrorSubject;

  /**
   * Does receive data when refreshing the access token failed
   */
  protected apiSessionExpiredSubject = new Subject<void>();
  public apiSessionExpired$: Observable<void> = this.apiSessionExpiredSubject;

  /**
   * Does receive data when an API/WMS call times out
   */
  protected serverOfflineSubject = new ReplaySubject<void>();
  public serverOffline$: Observable<void> = this.serverOfflineSubject;

  /**
   * Does receive data when the server returned a non-200 response
   */
  protected apiNotAvailableSubject = new ReplaySubject<void>();
  public apiNotAvailable$: Observable<void> = this.apiNotAvailableSubject;


  /**
   * Notifies WMS errors
   */
  protected wmsErrorSubject = new ReplaySubject<WMSErrorResponse>();
  public wmsError$: Observable<WMSErrorResponse> = this.wmsErrorSubject;

  /**
   * Publishes successful uploads
   */
  protected _uploadFileSubject = new Subject<StorageEntry>();
  public _uploadFile$: Observable<StorageEntry> = this._uploadFileSubject;

  protected _uploadResultSubject = new Subject<UploadResult>();
  public _uploadResult$: Observable<UploadResult> = this._uploadResultSubject;

  protected newDataEntryIdSubject = new Subject<number>();
  public newDataEntryId$: Observable<number> = this.newDataEntryIdSubject;

  protected featureUpdateSubject = new Subject<string>();
  /**
   * A feature was updates, publish their guid.
   */
  public featureUpdate$: Observable<string> = this.featureUpdateSubject;

  protected curMapPos: MapPos | null = null;
  protected client: string = "d";

  protected wsSubject = new Subject<any>();
  public ws$: Observable<any> = this.wsSubject;

  protected uppy!: Uppy; // gets initialized in constructor->setupUploads
  protected uploads: UploadMap = {};

  /**
   * Our currently selected project
   * @private
   */
  private projectId: number = 0;

  private clientSerial = uuidv4();

  /**
   * Our current access token
   * @private
   */
  private accessToken: string | null = null;

  private requestCnt = 0;

  private tokenTimeoutId: number | null = null;


  constructor(apiBasePath: string, private wmsBasePath: string) {
    super();

    this.apiClient = axios.create({
      baseURL: apiBasePath,
      responseType: 'json'
    });
    // inject access token into every request
    this.apiClient.interceptors.request.use(
      config => {
        // @ts-ignore
        config.headers["Authorization"] = "Bearer " + this.accessToken;
        // @ts-ignore
        config.headers["X-IGIS-Client"] = this.client + ":" + "1.0.0"; // TODO: specify real version
        // @ts-ignore
        config.headers["X-IGIS-Client-UUID"] = this.clientSerial;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    this.wmsClient = axios.create({
      baseURL: wmsBasePath,
      responseType: 'text' // for now we are using xml
    });
    // inject access token into every request
    this.wmsClient.interceptors.request.use(
      config => {
        // @ts-ignore
        config.headers["Authorization"] = "Bearer " + this.accessToken;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    this.setupUploads();

    // save new access token
    this.token$.subscribe(token => this.accessToken = token);


    ////////////// the following event handlers should not be in the API, should we expose all results?
    // and add the subscriptions to the IGIS-app-base class?

    // when project info was loaded: recount open data entries
    this.projectInfo$.subscribe((projInfo) => {
      projInfo.onNewDataSetEntryCnt();

      // we are loaded, connect our web socket client
      const wsClient = new WebsocketClient('/ws', this.accessToken, this.clientSerial, this.projectId);
      wsClient.messages$.subscribe((msg) => {
        this.onWSMsg(msg, projInfo);
      })
      // update our access token (for possible refresh, after connection loss)
      this.token$.subscribe(token => { if (token) wsClient.accessToken = token; });
    })

    // when a new data entry was added: reload this layer
    this.projectInfo$.pipe(
      switchMap(projectInfo => {
        return this._addDataEntry$.pipe(map((newDataEntry) => {
          return {projectInfo, newDataEntry};
        }))
      })
    ).subscribe(vals => {
      const projInfo = vals.projectInfo;
      const newDataSet = vals.newDataEntry.dataSet;
      // fix the open-cnt in the current dataset
      const dataSet = projInfo.getDataSetById(newDataSet.id);
      if (dataSet) {
        dataSet.openDataEntryCount = newDataSet.openDataEntryCount;
        // reload layers
        dataSet.reloadLayers();
      } else {
        console.log('update non-existing dataset?');
      }
      // we notify that maybe the ext feature info has changed
      const guid = vals.newDataEntry.featureGUID;
      console.log('notifying that feature ' + guid + ' has changed');
      this.featureUpdateSubject.next(guid);
    })

    // when a batch was finished: reload layers
    this.projectInfo$.pipe(
      switchMap(projectInfo => {
        return this._finishBatch$.pipe(map((dataSet) => {
          return {projectInfo, dataSet};
        }))
      })
    ).subscribe(vals => {
      const projInfo = vals.projectInfo;
      const newDataSet = vals.dataSet;
      // fix the open-cnt in the current dataset
      const dataSet = projInfo.getDataSetById(newDataSet.id);
      if (dataSet) {
        dataSet.openDataEntryCount = newDataSet.openDataEntryCount;
        // reload layers
        dataSet.reloadLayers();
      } else {
        console.log('update non-existing dataset?');
      }
    })

    // when a new feature was added: reload its layer
    this.projectInfo$.pipe(
      switchMap(projectInfo => {
        return this._addFeature$.pipe(map((newFeature) => {
          return {projectInfo, newFeature};
        }))
      })
    ).subscribe(vals => {
      const projInfo = vals.projectInfo;
      const newFeature = vals.newFeature;
      const layer = projInfo.getLayerById(newFeature.layerId)
      if (layer) {
        layer.reload();
      } else {
        console.log('does not have layer?');
      }
    })

    // when a new feature was deleted: reload its layer
    this.projectInfo$.pipe(
      switchMap(projectInfo => {
        return this._deleteFeature$.pipe(map((delFeatureResult) => {
          return {projectInfo, delFeatureResult};
        }))
      })
    ).subscribe(vals => {
      const projInfo = vals.projectInfo;
      const deleteFeatureResult = vals.delFeatureResult;
      const layer = projInfo.getLayerById(deleteFeatureResult.layerId);
      if (layer) {
        layer.reload();
      }
    })

    // when a feature was moved: reload its layer
    this.projectInfo$.pipe(
      switchMap(projectInfo => {
        return this._moveFeature$.pipe(map((moveFeatureResult) => {
          return {projectInfo, moveFeatureResult};
        }))
      })
    ).subscribe(vals => {
      const projInfo = vals.projectInfo;
      const result = vals.moveFeatureResult;
      const layer = projInfo.getLayerById(result.layerId);
      if (layer) {
        layer.reload();
      }
    })

    this._getProjectInfo$.subscribe(projInfo => {
      // we received the raw project-info, correct the info and publish it
      projInfo.transformProjectInfo()
      this.projectInfoSubject.next(projInfo);
    })

    this._addDataEntry$.subscribe((newDataEntry) => {
      this.newDataEntryIdSubject.next(newDataEntry.newEntryId);
      this.featureUpdateSubject.next(newDataEntry.featureGUID);
    })

    // react on changes to feature data
    // TODO: how to combine these? concat stops working after the first one..
    this._assocFile$.subscribe(changedFeature => {
      console.log('received feature update');
      this.featureUpdateSubject.next(changedFeature.guid);
    })
    this._disassocFile$.subscribe(changedFeature => {
      console.log('received feature update');
      this.featureUpdateSubject.next(changedFeature.guid);
    })
    this._deleteFile$.subscribe(changedFeature => {
      console.log('received feature update');
      this.featureUpdateSubject.next(changedFeature.guid);
    })
    this._addHref$.subscribe(uploadResult => {
      uploadResult.storageEntries.forEach(storageEntry => {
        this._uploadFileSubject.next(storageEntry);
      })
      this._uploadResultSubject.next(uploadResult);
    })
    this._uploadResult$.subscribe(uploadResult => {
      console.log('trying to reload from upload result');
      this.featureUpdateSubject.next(uploadResult.featureGUID);
    })

    this._projectList$.subscribe(res => {
      this.projectListSubject.next(res.projectList);
    })

    this._whoami$.subscribe(whoami => {
      // request project list after whoami
      this.projectList();
    })
  }

  public setProjectId(projectId: number) {
    this.projectId = projectId;
  }

  public async logoutCall(): Promise<void> {
    return this.apiClient.get('/auth/logout');
  }

  public async login(params: { email: string, pwd: string, otp: string|null }): Promise<LOGIN_RESULT> {
    try {
      const response = await this.apiClient.post('/auth/login', params);
      switch (response.status) {
        case 200:
          // success, we received an access-token
          const accessToken = response.data;
          this.tokenSubject.next(accessToken);
          // initiate project selection
          this.whoami();
          return LOGIN_RESULT.SUCCESS;
      }
    } catch (error) {
      const httpCode = error.response.status;
      switch (httpCode) {
        case 401:
          // check headers
          if (error.response.headers['x-igis-2fa-req']) {
            return LOGIN_RESULT.OTP_REQUIRED;
          }
          if (error.response.headers['x-igis-expired']) {
            return LOGIN_RESULT.EXPIRED;
          }
          break;
        case 500:
        return LOGIN_RESULT.FAILURE;
        default:
          console.log("received invalid status from login: " + error.status);
          return LOGIN_RESULT.FAILURE;
      }
    }

    // default return value is permission denied
    return LOGIN_RESULT.DENIED;
  }

  /**
   * Checks if an expiration date (in seconds since 1970...) will expire in the next 10 minutes.
   * @param expirationDate
   */
  public isNearExpire(expirationDate: number | undefined): boolean {
    if (!expirationDate) {
      return true;
    }
    const now = Date.now().valueOf() / 1000;
    const nowFuture = now + 10 * 60;
    return nowFuture > expirationDate;
  }

  public isTokenExpired(token: string): boolean {
    // check expiration date
    const jwt = jwtDecode(token);
    return this.isNearExpire(jwt.exp);
  }

  private clearTokenRefresh(): void {
    if (this.tokenTimeoutId) {
      window.clearTimeout(this.tokenTimeoutId);
      this.tokenTimeoutId = null;
    }
  }

  private scheduleTokenRefresh(): void {
    this.clearTokenRefresh();
    this.tokenTimeoutId = window.setTimeout(() => {
      //console.log("timeout triggered, check token");
      this.checkToken();
    }, 1000 * 60 * 3); // 3 minutes

  }

  private async fetchToken(justTry: boolean): Promise<string | null> {
    return this.apiClient.get('/auth/token')
      .then(response => {
        if (response.status == 200) {
          // we received a new access token
          const newAccessToken = response.data;
          this.tokenSubject.next(newAccessToken);
          this.scheduleTokenRefresh();
          return newAccessToken;
        } else {
          return null;
        }
      }).catch(error => {
        switch (error.response.status) {
          case 401:
            this.tokenSubject.next(null);
            // token expired, something else happened
            console.log('refresh token not valid anymore');
            if (!justTry) {
              this.apiSessionExpiredSubject.next();
            }
            break;
        }
        return null;
      })
  }

  public async checkToken(justTry: boolean = false): Promise<string | null> {
    this.clearTokenRefresh();
    if (this.accessToken) {
      if (!this.isTokenExpired(this.accessToken)) {
        this.scheduleTokenRefresh();
        return this.accessToken;
      }
    }
    // try a refresh call
    return await this.fetchToken(justTry);
  }

  private setupUploads(): void {
    // create our uppy object for managing file uploads

    // create resolver for adding authorization headers to request
    const headers = (file) => {
      return {
        authorization: `Bearer ${this.accessToken}`
      };
    };

    this.uppy = new Uppy({
      autoProceed: false,
      debug: true,
    }).use(XHR, {endpoint: "/will_be_changed", headers: headers});
    //.use(GoldenRetriever, {serviceWorker: false});

    // setup service worker
    /*if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .register(new URL('./worker/sw.ts', import.meta.url))
        .then((registration) => {
          console.log(
            'ServiceWorker registration successful with scope: ',
            registration.scope,
          );
        })
        .catch((error) => {
          console.log(`Registration failed with ${error}`);
        });
    } else {
      console.log('No service worker capability')
    }*/


    // set upload URL based on project id + feature uuid
    this.uppy.on('file-added', (file) => {
      const endpoint = `/api/${this.getProjectId()}/uploadfile/${encodeURIComponent(<string>file.meta.fguid)}`;
      this.uppy.setFileState(file.id, {
        xhrUpload: {
          ...(<any>file).xhrUpload,
          endpoint
        }
      })
    });

    let errorMask = [];

    // register handlers
    this.uppy.on('upload-success', (file, response) => {

      // structure of response parameter depends on upload plugin
      const status = response.status;
      const body = response.body;

      if (file) {
        // otherwise we cannot upload the same file twice
        this.uppy.removeFile(file.id);
      }

      if (status == 200) {
        // we received a 200 result, but maybe the call failed otherwise
        if (!body.success) {
          const errorMsg = body.error_msg;
          const errorCode = body.error_code;

          // maybe the caller masked some error code?
          if (errorMask) {
            for (let maskedErrorCode of errorMask) {
              if (maskedErrorCode == errorCode) {
                // the returned error code is masked, just return a null result
                // but do not notify any error handlers
                return null;
              }
            }
          }

          // notify all listeners that something went wrong
          // decide which channel to use
          switch (errorCode) {
            default:
              this.apiErrorSubject.next({
                errorCode: errorCode,
                errorMsg: errorMsg,
                call: 'uploadfile'
              });
              break;
          }
        } else {
          // everything went fine, create the object structure from the JSON string
          const uploadResult = new UploadResult(body.data, this);
          // notify all new storage entries
          uploadResult.storageEntries.forEach(storageEntry => {
            this._uploadFileSubject.next(storageEntry);
          })
          this._uploadResultSubject.next(uploadResult);
        }
      } else {
        console.log('status other than 200?');
      }
    })

    this.uppy.on('upload-error', (file, error, response) => {
      // structure of response parameter depends on upload plugin
      const status = response?.status;
      if (file) {
        // otherwise we cannot upload the same file twice
        this.uppy.removeFile(file.id);
      }
      console.log('upload-error');
      if ((<any>error).isNetworkError) {
        this.serverOfflineSubject.next(); // notify all listeners that an api call timed out
      } else {
        this.apiNotAvailableSubject.next();
      }
    })

  }

  public setMapPos(curPos: MapPos): void {
    this.curMapPos = curPos;
  }

  public setClient(client: string) {
    this.client = client;
  }

  /**
   * Called on receiving a websocket message from the server
   * @param msg
   * @param projInfo
   * @protected
   */
  protected onWSMsg(msg: WsMessage, projInfo: ProjectInfo) {
    const action = msg?.action;
    switch (action) {
      case 'rl-layer':
        console.log('reloading layer from ws', msg.layerId);
        const layerId = msg.layerId;
        if (layerId) {
          projInfo.getLayerById(layerId)?.reload();
        }
        break;
      case 'rl-feature':
        console.log('reloading feature from ws', msg.featureGUID);
        const featureGUID = msg.featureGUID;
        if (featureGUID) {
          this.featureUpdateSubject.next(featureGUID);
        }
        break;
    }
  }

  protected async rpc<T>(url: string, params: RPCParams, model: Newable<T>, errorMask?: ERROR_CODE[]): Promise<ApiResult<T> | null> {

    if (!params) { // maybe we were given null parameters
      params = {};
    }

    // set last position in parameters
    if (this.curMapPos) {
      params.lastPos = this.curMapPos;
    }

    try {

      // increment request cnt
      this.requestCnt++;
      this.requestActiveSubject.next(true);
      const accessToken = await this.checkToken();
      if (!accessToken) {
        return null;
      }
      const response = await this.apiClient.post<ApiResponse>(url, params);
      // we received a 200 result, but maybe the call failed otherwise
      return this.parseRequestResult(response, url, model, errorMask);
    } catch (error) {
      // something went wrong, e.g. HTTP request failed with code other than 200
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        // log the error via jsnlog
        //console.log(error.response.status);
        //console.log(error.response.headers);
        this.apiNotAvailableSubject.next();

      } else if (error.request) {
        // we are offline or timeout happened
        this.serverOfflineSubject.next(); // notify all listeners that an api call was timed out
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
      }

      throw error;
    } finally {
      this.requestCnt--;
      if (this.requestCnt <= 0) {
        this.requestActiveSubject.next(false);
      }
    }
  }

  private async parseRequestResult<T>(response: AxiosResponse<ApiResponse>, call: string, model: Newable<T>, errorMask?: ERROR_CODE[]): Promise<ApiResult<T> | null> {
    // we received a 200 result, but maybe the call failed otherwise
    if (!response.data.success) {
      const errorMsg = response.data.error_msg;
      const errorCode = response.data.error_code;

      // maybe the caller masked some error code?
      if (errorMask) {
        for (let maskedErrorCode of errorMask) {
          if (maskedErrorCode == errorCode) {
            // the returned error code is masked, just return the error code result
            // but do not notify any error handlers
            return {data: null, errorCode: errorCode};
          }
        }
      }

      // notify all listeners that something went wrong
      // decide which channel to use
      switch (errorCode) {
        default:
          this.apiErrorSubject.next({
            errorCode: errorCode,
            errorMsg: errorMsg,
            call: call
          });
          break;
      }
      // we return null as error
      return {data: null, errorCode: errorCode};

    } else {
      // everything went fine, create the object structure from the JSON string
      return {data: new model(response.data.data, this), errorCode: null};
    }
  }

  protected async rpcGETDownload(url: string): Promise<string | null> {
    try {

      // increment request cnt
      this.requestCnt++;
      this.requestActiveSubject.next(true);
      const accessToken = await this.checkToken();
      if (!accessToken) {
        return null; // we cannot request anything
      }
      const response = await this.apiClient.get<string>(url);
      if (response.status == 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      // something went wrong, e.g. HTTP request failed with code other than 200
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        const errResp = error.response;
        const httpStatus = errResp.status;
        switch (httpStatus) {
          case 401:
            // unauthorized
            return null;
          default:
            this.apiNotAvailableSubject.next();
        }
      } else if (error.request) {
        // we are offline or timeout happened
        this.serverOfflineSubject.next(); // notify all listeners that an api call was timed out
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
      }

      throw error;
    } finally {
      this.requestCnt--;
      if (this.requestCnt <= 0) {
        this.requestActiveSubject.next(false);
      }
    }
  }


  protected async rpcGET<T>(url: string, model: Newable<T>, errorMask?: ERROR_CODE[]): Promise<ApiResult<T> | null> {
    try {

      // increment request cnt
      this.requestCnt++;
      this.requestActiveSubject.next(true);
      const accessToken = await this.checkToken();
      if (!accessToken) {
        return null; // we cannot request anything
      }
      const response = await this.apiClient.get<ApiResponse>(url);
      return this.parseRequestResult(response, url, model, errorMask);

    } catch (error) {
      // something went wrong, e.g. HTTP request failed with code other than 200
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        const errResp = error.response;
        const httpStatus = errResp.status;
        switch (httpStatus) {
          case 401:
            // unauthorized
            return null;
          default:
            this.apiNotAvailableSubject.next();
        }
      } else if (error.request) {
        // we are offline or timeout happened
        this.serverOfflineSubject.next(); // notify all listeners that an api call was timed out
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
      }

      throw error;
    } finally {
      this.requestCnt--;
      if (this.requestCnt <= 0) {
        this.requestActiveSubject.next(false);
      }
    }
  }

  public getWMSUrl(request: "pi" | "fi" | "lg" | "map"): string {
    return this.wmsBasePath + request + "/" + this.projectId;
  }

  public printDownload(uuid: string): void {
    window.location.href = '/api/file/download/' + uuid;
  }

  /**
   * Uses /api/<xxx>/uploadfile/<guid> endpoint to transfer file to server.
   * The upload is associated with the given feature.
   * @param file
   * @param feature
   */
  public async uploadFile(file, feature: Feature): Promise<UploadResult | null> {

    const featureGUID = feature.guid;
    const uppyFile = {
      name: file.name,
      type: file.type,
      data: file,
      meta: {
        fguid: featureGUID
      }
    };

    // add to uppy queue
    const newFileId = this.uppy.addFile(uppyFile);
    this.uppy.upload();
    // add to our list of running uploads*/
    return null;
  }

  protected async wmsRequest(params, request: "fi" | "lg"): Promise<AxiosResponse<any> | null> {
    try {

      // increment request cnt
      this.requestCnt++;
      this.requestActiveSubject.next(true);
      await this.checkToken();

      return await this.wmsClient.get('/' + request + "/" + this.projectId, {params: params});

    } catch (error) {
      // something went wrong, e.g. HTTP request failed with code other than 200
      if (error.response) {

        this.wmsErrorSubject.next({
          code: '',
          errorMsg: error.response.data
        });

      } else if (error.request) {
        // we are offline or timeout happened
        this.serverOfflineSubject.next(); // notify all listeners that an wms/api call has timed out
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
      }

      //throw error;
      return null;

    } finally {

      this.requestCnt--;
      if (this.requestCnt <= 0) {
        this.requestActiveSubject.next(false);
      }

    }
  }

  public async wmsFeatureInfo(queryLayers: Layer[], position: Point, bounds: LatLngBounds,
                              mapSize: Point, projectInfo: ProjectInfo): Promise<FIResultFromXML | null> {

    // we make the query in EPSG:3857
    // convert bounds from lat/lng
    const crs = CRS.EPSG3857;
    const bboxNW = crs.project(bounds.getNorthWest());
    const bboxSE = crs.project(bounds.getSouthEast());

    const params = {
      INFO_FORMAT: 'text/xml',
      QUERY_LAYERS: queryLayers.map(layer => {
        return layer.wmsName
      }).join(','),
      I: Math.round(position.x),
      J: Math.round(position.y),
      BBOX: bboxNW.x + "," + bboxSE.y + "," + bboxSE.x + "," + bboxNW.y,
      CRS: 'EPSG:3857',
      WITH_GEOMETRY: true,
      WIDTH: mapSize.x,
      HEIGHT: mapSize.y,
      FI_POINT_TOLERANCE: 32,
      FI_LINE_TOLERANCE: 16,
      FI_POLYGON_TOLERANCE: 8,
      FEATURE_COUNT: 10
    };
    const response = await this.wmsRequest(params, "fi");
    if (response) {
      return new FIResultFromXML(response.data, projectInfo);
    } else {
      return null;
    }
  }

  public async wmsFeatureInfoByGUID(layerId: number, guid: string): Promise<FI | null> {
    const projectInfo = await lastValueFrom(this.projectInfo$.pipe(take(1)));
    const layer = projectInfo.getLayerById(layerId);
    if (!layer) {
      console.log('cannot find layer for layer_id ' + layerId);
      return null;
    }
    // else: make the wms request
    const params = {
      CRS: 'EPSG:3857',
      INFO_FORMAT: 'text/xml',
      QUERY_LAYERS: layer.wmsName,
      LAYERS: layer.wmsName,
      WITH_GEOMETRY: true,
      FILTER: layer.wmsName + ':"guid" = \'' + guid + '\''
    }
    return this.wmsRequest(params, "fi").then(response => {
      if (response) {
        const fiResult = new FIResultFromXML(response.data, projectInfo);
        for (let fiLayer of fiResult.resultLayers) {
          const features = fiLayer.features;
          for (let feature of features) {
            if (feature.guid === guid) {
              return feature;
            }
          }
        }
      }
      return null;
    });
  }

  public notifyFeatureUpdate(guid: string) {
    this.featureUpdateSubject.next(guid);
  }
}
