// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styling of option templates in data entry form */
.link_style {
    cursor:pointer;
    color:blue;
    text-decoration:underline;
}

.x-tree-node-black {
    color: black;
}

.multiline-row {
    overflow: auto !important;
    white-space: normal !important;
    text-overflow: ellipsis;
    display: block;
}

.x-grid-row .x-grid-cell-inner {
    white-space: normal;
}
.x-grid-row-over .x-grid-cell-inner {
    white-space: normal;
}

.igis-button-disable .x-btn-icon-el-default-toolbar-medium {
    /*color: #157fcc;*/
    opacity: 0.5;
}

.x-btn-inner-default-toolbar-medium {
    padding: 0;
}

.igis-empty-files {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.igis-empty-files span {
    text-align: center;
    opacity: 0.5;
}

.igis-center-tree-icon {
    top: 3px
}


`, "",{"version":3,"sources":["webpack://./src/css/igis.css"],"names":[],"mappings":"AAAA,mDAAmD;AACnD;IACI,cAAc;IACd,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,8BAA8B;IAC9B,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI;AACJ","sourcesContent":["/* Styling of option templates in data entry form */\n.link_style {\n    cursor:pointer;\n    color:blue;\n    text-decoration:underline;\n}\n\n.x-tree-node-black {\n    color: black;\n}\n\n.multiline-row {\n    overflow: auto !important;\n    white-space: normal !important;\n    text-overflow: ellipsis;\n    display: block;\n}\n\n.x-grid-row .x-grid-cell-inner {\n    white-space: normal;\n}\n.x-grid-row-over .x-grid-cell-inner {\n    white-space: normal;\n}\n\n.igis-button-disable .x-btn-icon-el-default-toolbar-medium {\n    /*color: #157fcc;*/\n    opacity: 0.5;\n}\n\n.x-btn-inner-default-toolbar-medium {\n    padding: 0;\n}\n\n.igis-empty-files {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n}\n\n.igis-empty-files span {\n    text-align: center;\n    opacity: 0.5;\n}\n\n.igis-center-tree-icon {\n    top: 3px\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
